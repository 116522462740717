import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const CertificateCompletion = () => (
	<Layout>
		<SEO
			title="NARM Certificate of Completion"
			description="A certificate of completion is issued upon completion of all requirements for the NARM Practitioner training. "
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1>NARM Certificate of Completion</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div className="row">
					<div className="col-md-6 mx-auto text-left">
						<div className="intro mb-4 ">
							<h2 className="text-primary">
								<span className="text-orange">NARM&nbsp;</span>Certificate of
								Completion
							</h2>
							<p className="text-muted">
								{" "}
								<strong>
									A certificate of completion is issued upon completion of all
									requirements for the NARM Practitioner training.
								</strong>{" "}
							</p>
							<p className="text-muted">
								{" "}
								<strong className="text-orange">
									Licensed Psychotherapists that complete all requirements will
									receive a NARM Therapist Certificate
								</strong>{" "}
							</p>
							<p className="text-muted">
								{" "}
								<strong className="text-orange">
									All Non-Licensed Therapists that graduate from the training
									and complete all the requirments will receive a NARM
									Practitioner Certificate
								</strong>{" "}
							</p>
							<hr />
						</div>
					</div>
					<div class="col-md-6 order-2 order-md-1">
						<div class="justify-content-between align-items-center">
							<div className="intro mb-4 ">
								<div className="col-md-12 mx-auto text-left">
									<h2 className="text-primary">
										<span className="text-orange" />
										Requirements
									</h2>
								</div>
							</div>
							<ul className="checkmark">
								<li>
									<p className="">
										{" "}
										Completion of all course requirements, including attending
										every day of each module (up to 1 module may be satisfied
										through video)
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										A minimum of ten (10) Experiential Consultation hours.&nbsp;
										<i>
											$125/consultation with training assistants; cost may be
											higher with faculty
										</i>
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										A minimum of ten (10) Case Consultation hours, at least four
										(4) of which must be with NARM faculty; &nbsp;
										<i>
											$125/individual consultation and $75/group consultation
											with training assistants; cost may be higher with faculty
										</i>
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										Case consultations can be completed individually or in group
										format – there will be 1 group Case Consultation for each
										training module (other than Module 1).
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										Please Note: two (2) hours of group Case Consultation are
										equivalent to one (1) hour of personal Case Consultation.
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										Consultation requirements must be completed no later than
										one year after the completion of course work.
									</p>
								</li>
								<li>
									<p className="">
										{" "}
										An evaluation of your NARM Practitioner Skills may be
										requested before completion of your program.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default CertificateCompletion
